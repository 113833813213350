import styles from './Slider.module.scss'
import {useDispatch, useSelector} from "react-redux";
import Button from "/components/common/button";
import {useRouter} from "next/router";

export default function LandingsSlider({items}) {
    const router = useRouter();

    return (
        <div className={styles.Slider}>
            {items?.length > 0 && items.map(slider => (
                <div
                    key={slider.ID}
                    className={styles.Back}
                    style={{ 'background-image': `url(${slider.PICTURE_SRC})` }}
                >
                    <div className='container'>
                        <div className={styles.Content}>
                            <h1>{slider.NAME}</h1>
                            <div className={styles.Title} dangerouslySetInnerHTML={{__html:slider.PREVIEW_TEXT}}></div>
                            {slider.PROPERTY_LINK_VALUE && (
                                <Button isLandings={true} label={'Подробнее'} onClick={() => {
                                    router.push(slider.PROPERTY_LINK_VALUE)
                                }}/>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

