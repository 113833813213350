import styles from './index.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {fetchMainNumbers} from "../../../redux/action-creaters/content";
import Link from 'next/link'

export default function LandingsNumbers() {
    const dispatch = useDispatch();
    const {mainNumbers} = useSelector(state => state.content)
    const {content} = useSelector(state => state.meta)

    return (
        <div className={styles.Numbers}>
            <div className={styles.Items}>
                {mainNumbers.map(number => (
                    <div key={number.ID}>
                        <div className={styles.Value}>
                            <div>более</div>
                            <span className={styles.Num}>{number['~PROPERTY_NUM_VALUE']}</span>
                            <span>{number.PROPERTY_MAGNITUDE_VALUE}</span>
                        </div>

                        <span className={styles.Name}>{number.NAME}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}