import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {fetchMainPopular} from "../../../redux/action-creaters/content";
import styles from "./index.module.scss";
import Link from 'next/link'

export default function Sections() {
    const dispatch = useDispatch();
    const {mainPopular} = useSelector(state => state.content)

    return (
        <div className={styles.Sections}>
            <h2>Популярные категории</h2>
            {mainPopular.length > 0 && (
                <div className={styles.SectionsList}>
                    <Link key={mainPopular[0].ID} prefetch={false}  href={mainPopular[0].PROPERTY_LINK_VALUE}>
                        <a style={{backgroundImage: 'url(sects/1.jpg)'}}>
                            <h3>{mainPopular[0].NAME}</h3>
                            <div>{mainPopular[0].PREVIEW_TEXT}</div>
                        </a>
                    </Link>
                    <Link key={mainPopular[1].ID} prefetch={false} href={mainPopular[1].PROPERTY_LINK_VALUE}>
                        <a style={{backgroundImage: 'url(sects/2.jpg)'}}>
                            <h3>{mainPopular[1].NAME}</h3>
                            <div>{mainPopular[1].PREVIEW_TEXT}</div>
                        </a>
                    </Link>
                    <Link key={mainPopular[3].ID} prefetch={false}  href={mainPopular[3].PROPERTY_LINK_VALUE}>
                        <a style={{backgroundImage: 'url(sects/3_.jpg)'}}>
                            <h3>{mainPopular[3].NAME}</h3>
                            <div>{mainPopular[3].PREVIEW_TEXT}</div>
                        </a>
                    </Link>
                    <Link key={mainPopular[2].ID} prefetch={false}  href={mainPopular[2].PROPERTY_LINK_VALUE}>
                        <a style={{backgroundImage: 'url(sects/4.jpg)'}}>
                            <h3>{mainPopular[2].NAME}</h3>
                            <div>{mainPopular[2].PREVIEW_TEXT}</div>
                        </a>
                    </Link>

                </div>
            )}
        </div>
    )
}