import styles from './index.module.scss'
import {useDispatch, useSelector} from "react-redux";
import Link from 'next/link'
import Button from "/components/common/button"
import { useRouter } from 'next/router'

export default function Reviews() {
    const router = useRouter()

    const dispatch = useDispatch();
    const {mainReviews} = useSelector(state => state.content)
    
    return (
        <div className={styles.News}>
            <h2>
                Отзывы о нас
                <Link href={'/reviews/'}>Все отзывы</Link>
            </h2>
            <div className={styles.NewsList}>
                {mainReviews.map(review => (
                    <>
                        {review.PROPERTY_TYPE_VALUE === 'Кнопка' && (
                            <a href={review.PROPERTY_LINK_VALUE}
                                target="_blank"
                                rel="noreferrer"
                                key={review.ID}
                                className={styles.Button}
                            >
                                <div className={styles.Img}>
                                    <img src={review.ICON_SRC}/>
                                </div>
                                <div className={styles.Name}>
                                    {review.NAME}
                                </div>
                                <div className={styles.Qnt}>
                                    Количество отзывов: {review.PROPERTY_COUNT_VALUE}
                                </div>
                                <div className={styles.Rating}>
                                    <div className="video-sources-block__rating-val">
                                        {review.PROPERTY_RATING_VALUE}
                                    </div>
                                </div>
                            </a>
                        )}
                    </>
                ))}
            </div>
   
        </div>
    )
}