import styles from './Slider.module.scss'
import {useDispatch, useSelector} from "react-redux";
import Button from "/components/common/button";
import {useRouter} from "next/router";

export default function Slider() {
    const dispatch = useDispatch();
    const {mainSlider} = useSelector(state => state.content)
    const router = useRouter();

    return (
        <div className={styles.Slider}>
            {mainSlider.map(slider => (
                <div
                    key={slider.ID}
                    className={styles.Back}
                    style={{ 'background-image': `url(${slider.PICTURE})` }}
                >
                    <div className='container'>
                        <div className={styles.Content}>
                            <h1>{slider.NAME}</h1>
                            <div className={styles.Title} dangerouslySetInnerHTML={{__html:slider.PREVIEW_TEXT}}></div>
                            {slider.PROPERTY_BUTTON_TEXT_VALUE && (
                                <Button label={slider.PROPERTY_BUTTON_TEXT_VALUE} onClick={() => {
                                    router.push(slider.PROPERTY_BUTTON_LINK_VALUE)
                                }}/>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

