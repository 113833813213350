import styles from './index.module.scss'
import {useSelector} from "react-redux";
import Link from 'next/link'

export default function LandingsReviews() {
    const {mainReviews} = useSelector(state => state.content)
    
    return (
        <div className={styles.News}>
            <h2>
                Отзывы
                <Link href={'/reviews/'}>
                    <a>Все отзывы
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.7121 12L9.70111 5.98999L8.28711 7.40399L12.8871 12.004L8.28711 16.597L9.70111 18.011L15.7121 12Z" fill="#161616"/>
                        </svg>
                    </a>
                </Link>
            </h2>
            <div className={styles.NewsList}>
                {mainReviews.map(review => (
                    <>
                        {review.PROPERTY_TYPE_VALUE === 'Кнопка' && (
                            <a href={review.PROPERTY_LINK_VALUE}
                                target="_blank"
                                rel="noreferrer"
                                key={review.ID}
                                className={styles.Button}
                            >
                                <div>
                                    <div className={styles.Img}>
                                        <img src={review.ICON_SRC}/>
                                    </div>
                                    <div className={styles.Qnt}>
                                        Количество отзывов: {review.PROPERTY_COUNT_VALUE}
                                    </div>
                                </div>
                                <div className={styles.Rating}>
                                    {review.PROPERTY_RATING_VALUE}
                                </div>
                            </a>
                        )}
                    </>
                ))}
            </div>
   
        </div>
    )
}