import styles from "./index.module.scss";
import Videos from "~/components/landings/videos";
import LandingsSlider from "~/components/landings/slider";
import LandingsModels from "~/components/landings/models";
import LandingsAdv from "~/components/landings/adv";
import LandingsAbout from "~/components/landings/about";
import LandingsNumbers from "~/components/landings/numbers";
import LandingsReviews from "~/components/landings/reviews";
import LandingsContacts from "~/components/landings/contacts";
import Faq from "~/components/landings/faq";

export default function LandingsHome({slider, models, adv}: any) {
    return (
        <div className={styles.Landings}>
            <LandingsSlider items={slider}/>
            <div className='container'>
                <div className={styles.LandingsModels}>
                    <LandingsModels items={models}/>
                </div>
            </div>
            <div className={styles.LandingsGray}>
                <div className='container'>
                    <div className={styles.LandingsBloks}>
                        <LandingsAdv items={adv}/>
                        <LandingsAbout/>
                        <LandingsNumbers/>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className={styles.LandingsBloks}>
                    <LandingsReviews/>
                    <Videos/>
                    <LandingsContacts/>
                </div>
            </div>

            <div className={styles.LandingsGray}>
                <div className='container'>
                    <div className={styles.LandingsBloks}>
                        <Faq/>
                    </div>
                </div>
            </div>
        </div>
    )
}