import styles from './index.module.scss'
import {useSelector} from "react-redux";
import Link from 'next/link'

export default function News() {
    const {mainProductReviews} = useSelector(state => state.content)
    
    return (
        <div className={styles.News}>
            <h2>
                Обзоры на товары
                <Link href={'/obzory-na-tovary/'}>Все обзоры</Link>
            </h2>
            <div className={styles.NewsList}>
                {mainProductReviews?.map(item => (
                    <Link key={item.ID} href={item.DETAIL_PAGE_URL}>
                        <a>
                            <div className={styles.Picture}>
                                <img src={item.PICTURE_SRC}/>
                            </div>
                            <div className={styles.Info}>
                                <div className={styles.Name}>
                                    {item.NAME}
                                </div>
                                <span className={styles.Bottom}>{ item.FORMATTED_DATE } · { item.SECTION.NAME }</span>
                            </div>
                        </a>
                    </Link>
                ))}
            </div>
        </div>
    )
}