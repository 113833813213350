import Slider from '@/components/main/slider'
import Selector from '@/components/main/selector'
import Sections from "@/components/main/sections";
import Numbers from "@/components/main/numbers";
import Shops from "@/components/main/shops";
import Adv from "@/components/main/adv";
import News from "@/components/main/news";
import styles from "./index.module.scss";
import ProductReviews from '@/components/main/productReviews';
import Reviews from '@/components/main/reviews';
import Portfolio from '@/components/main/portfolio';
import Videos from "~/components/main/videos";

export default function FarkopHome({host}: any) {
    return (
        <>
            <Slider/>
            <div className='container'>
                <div className={styles.Top}>
                    <div className={styles.Selector}>
                        <Selector/>
                    </div>
                    <Sections/>
                </div>
            </div>
            <Numbers/>
            <div className='container'>
                <div className={styles.Bottom}>
                    <Shops rows={3} host={host} isBaltex={false} isShowShopsForRegions={false}/>
                    <Adv/>
                    <div className={styles.News}>
                        <News hideDate={true}/>
                        <ProductReviews/>
                    </div>
                    <Portfolio/>
                    <Reviews/>
                    <Videos/>
                </div>
            </div>
        </>
    )
}