import styles from './index.module.scss'
import {useDispatch, useSelector} from "react-redux";
import Link from 'next/link'
import Button from "/components/common/button"
import { useRouter } from 'next/router'

export default function Portfolio() {
    const router = useRouter()

    const dispatch = useDispatch();
    const {mainPortfolio} = useSelector(state => state.content)
    
    return mainPortfolio.length === 0 ? null : (
        <div className={styles.News}>
            <h2>
                Портфолио
                <Link href={'/nashe-portfolio/'}>Все работы</Link>
            </h2>
            <div className={styles.NewsList}>
                {mainPortfolio?.map(item => (
                    <Link key={item.ID} href={item.DETAIL_PAGE_URL}>
                        <a>
                            <div className={styles.Picture}>
                                <img src={item.PICTURE_SRC}/>
                            </div>
                            <div className={styles.Info}>
                                <div className={styles.Name}>
                                    {item.NAME}
                                </div>
                                <span className={styles.Bottom}>{ item.FORMATTED_DATE } · { item.SECTION.NAME }</span>
                            </div>
                        </a>
                    </Link>
                ))}
            </div>
        </div>
    )
}