import styles from './index.module.scss'

export default function LandingsAdv({items}) {

    return (
        <div className={styles.Adv}>
            <h2>Наши преимущества</h2>

            <div className={styles.Items}>
                {items?.map(adv => (
                    <div key={adv.ID} className={styles.Item}>
                        <span className={styles.Image}>
                            <img src={adv.PICTURE_SRC}/>
                        </span>
                        <span className={styles.Name}>{adv.NAME}</span>
                        <span className={styles.Text}>{adv.PREVIEW_TEXT}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}