import styles from './index.module.scss'
import Link from "next/link";
import {useDispatch, useSelector} from "react-redux";

export default function LandingsAbout() {
    const dispatch = useDispatch();
    const {content} = useSelector(state => state.meta)

    return (
        <div className={styles.About}>
            <div className={styles.Info}>
                <h2>О компании</h2>
                <Link href='/about/'>
                    <a>Подробнее о компании
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.7121 12L9.70111 5.98999L8.28711 7.40399L12.8871 12.004L8.28711 16.597L9.70111 18.011L15.7121 12Z" fill="#FADE74"/>
                        </svg>
                    </a>
                </Link>
            </div>

            <div className={styles.Text}>
                <div dangerouslySetInnerHTML={{ __html: content }}/>
            </div>
        </div>
    )
}