import styles from './Slider.module.scss'
import {useDispatch, useSelector} from "react-redux";
import Button from "/components/common/button";
import {useRouter} from "next/router";

export default function BaltexSlider({items}) {
    return (
        <div className={styles.Slider}>
            {typeof items !== "undefined" && items?.map(slider => (
                <div
                    key={slider.ID}
                    className={styles.Back}
                    style={{ 'background-image': `url(${slider.PICTURE_SRC})` }}
                >
                    <div className='container'>
                        <div className={styles.Content}>
                            <h1>{slider.NAME}</h1>
                            <div className={styles.Title} dangerouslySetInnerHTML={{__html:slider.PREVIEW_TEXT}}></div>
                            {slider.PROPERTY_LINK_VALUE && (
                                <a href={slider.PROPERTY_LINK_VALUE}>Перейти в каталог
                                    <span>
                                        <svg width="9" height="10" viewBox="0 0 9 10" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3 9L7 5L3 1" stroke="white" strokeWidth="2"/>
                                            <path d="M0 5L6.5 5" stroke="white" strokeWidth="2"/>
                                        </svg>
                                    </span>
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

